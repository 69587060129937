.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 80%;
}


.modal-content button {
    margin: 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-content button.cancel {
    background-color: #ccc;
    color: #000;
}

.time-picker {
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 10px;
    font-size: 16px;
    margin-left: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.event-modal-header {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 5px;
}

.event-container {
    display: flex;
    margin-top: 6px;
}

.event-svg {
    margin-right: 8px;
}

.event-control-form .title {
    font-size: 14px;
}

.event-control-form .description {
    font-size: 12px;
}

@media (max-width: 768px) {
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        width: 100%;
        padding: 10px;
        max-width: none;
    }
}

@media (orientation: landscape) and (max-height: 500px) {
    .modal-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .modal .xs {
        height: auto;
        /* Permitir que crezca según el contenido */
        max-height: 80vh;
        /* Evitar que se salga de la pantalla */
        max-width: 1550px !important;
        color: black;
        overflow-y: auto;
    }
}
.container-perfiles {
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
}

.info-membresia {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
    max-width: 500px;
}

.info-membresia h2 {
    color: #3498db;
    font-size: 24px;
    font-weight: bold;
}

.membresia-details p {
    font-size: 16px;
    line-height: 1.6;
    margin: 10px 0;
    text-align: center;
}

.info-membresia strong {
    color: #000000;
    text-align: center;
}

.highlight {
    color: #000000;
}

/* PERFILES FORMULARIO */
.crear-perfil-container {
    max-width: 800px;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.plan {
    justify-content: center;
    align-items: center;
}

.plan span {
    font-size: 30px;
    font-weight: bold;
    color: #3498db;
    text-align: center;
    justify-content: center;
    align-items: center;
}


.crear-perfil-container h2 {
    color: #3498db;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.crear-perfil-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.crear-perfil-label {
    margin-bottom: 10px;
    font-size: 16px;
}


.crear-perfil-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.crear-perfil-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.crear-perfil-ul li {
    margin-bottom: 8px;
}

.crear-perfil-ul label {
    display: flex;
    align-items: center;
}

.crear-perfil-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.crear-perfil-button:hover {
    background-color: #2980b9;
}

.perfiles-creados-container {
    text-align: center;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    margin-left: 50px;
}

.perfiles-creados-container h2 {
    color: #3498db;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.perfiles-map {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.perfiles-creados-container h2 {
    color: #3498db;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.perfil-creado {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 320px;
    max-width: 320px;
    height: 220px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
}

.perfil-creado:hover {
    background-color: #ececec;
}

.ul-profile-tabs-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.li-profile-tab {
    display: flex;
    padding: 10px 15px;
    border-block-end: 4px solid #ccc;
    cursor: pointer;
}

.li-profile-tab-active {
    border-block-end: 4px solid #3498db;
}

.nombre-perfil {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #333;
}

.opciones-perfil {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.editar-perfil-button {
    background-color: #ddd;
    color: #333;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    width: 45%;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.editar-perfil-button:hover,
.eliminar-perfil-button:hover {
    background-color: #bbb;
    color: #fff;
}

.modal-content {
    height: 600px;
    max-width: 800px;
    color: black;
    overflow-y: auto;
}

.modal .xs {
    height: 640px;
    max-width: 350px;
    color: black;
    overflow-y: auto;
}

.modal-content span {
    font-size: 30px;
    font-weight: bold;
    color: #3498db;
    text-align: center;
}

.opciones-acceso {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.opciones-acceso input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #3498db;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
}

.opciones-acceso li {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

}

.opciones-acceso input:checked {
    background-color: #3498db;
}

.opciones-acceso label {
    cursor: pointer;
}

.accesos-perfil {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}


.accesos-perfil strong {
    text-align: start;
    margin-bottom: 5px;
}

.accesos-perfil ul {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.accesos-perfil li {
    width: 46%;
    margin-bottom: 5px;
}

.opciones-perfil button {
    background-color: #3498db;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease-in-out;
}

.opciones-perfil button:hover {
    background-color: #2980b9;
}


.ul-lista {
    display: flex;
    list-style-type: none;
}

.ul-lista {
    list-style-type: none;
    padding: 0;
    color: #2980b9;
}

.li-lista-titulo {
    display: flex;
    align-items: center;
}

.li-lista {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

.item-container {
    display: flex;
    align-items: center;
}

.checkbox-label {
    margin-left: 10px;
}

.li-lista-subtittle {
    border-block-end: 2px solid #529bcb;
}

.sublista {
    margin-left: 20px;
    padding: 0;
}

.hijo {
    list-style: none;
    color: #163a52;
}

.nieto {
    list-style: none;
    color: rgb(80, 80, 80);
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.header-title {
    width: 100%;
    text-align: center;
}

.header-title .cite-format-time {
    margin-top: 2px;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: .2px;
}


.header-title .format-time-GMT {
    margin-top: 2px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .2px;
}



@media (max-width: 768px) {
    .perfiles-map {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .perfiles-creados-container {
        margin-left: 0;
    }

    .perfiles-creados-container h2 {
        margin-bottom: 20px;
    }

    .perfil-creado {
        margin-left: 0;
    }

    .crear-perfil-container {
        margin: 20px 0;
    }

    .crear-perfil-container h2 {
        margin-bottom: 20px;
    }

    .crear-perfil-form {
        align-items: center;
    }

    .crear-perfil-input {
        width: 100%;
    }

    .crear-perfil-ul {
        padding: 0;
        margin: 0;
    }

    .crear-perfil-ul li {
        margin-bottom: 8px;
    }

    .crear-perfil-ul label {
        display: flex;
        align-items: center;
    }

    .crear-perfil-button {
        width: 100%;
    }

    .ul-profile-tabs-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .li-profile-tab {
        padding: 10px 15px;
        border-block-end: 4px solid #ccc;
        cursor: pointer;
    }

    .li-profile-tab-active {
        border-block-end: 4px solid #3498db;
    }

    .nombre-perfil {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        color: #333;
    }

    .opciones-perfil {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .editar-perfil-button {
        width: 100%;
    }

    .eliminar-perfil-button {
        width: 100%;
    }

    .modal-content {
        height: 600px;
        max-width: 800px;
        color: black;
        overflow-y: auto;
    }

    .modal-content span {
        font-size: 30px;
        font-weight: bold;
        color: #3498db;
        text-align: center;
    }

    .opciones-acceso {
        display: flex;
        flex-direction: column;
        margin-top: 10px
    }

    .checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    .opciones-acceso input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 16px;
        height: 16px;
        border: 2px solid #3498db;
        border-radius: 4px;
        margin-right: 8px;
        cursor: pointer;
    }

    .opciones-acceso input:checked {
        background-color: #3498db;
    }

    .opciones-acceso label {
        cursor: pointer;
    }

    .accesos-perfil {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    }

    .accesos-perfil strong {
        text-align: start;
        margin-bottom: 5px;
    }

    .accesos-perfil ul {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align: start;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .accesos-perfil li {
        width: 46%;
        margin-bottom: 5px;
    }

    .opciones-perfil button {
        width: 100%;
    }

    .ul-lista {
        display: flex;
        flex-direction: column;
        list-style-type: none;
    }

    .ul-lista {
        list-style-type: none;
        padding: 0;
        color: #2980b9;
    }

    .li-lista-titulo {
        display: flex;
        align-items: center;
    }

    .li-lista {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
    }

    .item-container {
        display: flex;
        align-items: center;
    }

    .checkbox-label {
        margin-left: 10px;
    }

    .li-lista-subtittle {
        border-block-end: 2px solid #529bcb;
    }

    .sublista {
        margin-left: 20px;
        padding: 0;
    }

    .hijo {
        list-style: none;
        color: #163a52;
    }

    .nieto {
        list-style: none;
        color: rgb(80, 80, 80);
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}